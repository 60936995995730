/** @format */

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    startingPoint: "",
    startingPointAddressId: "",
    vehicles: 1,
    destinationAddresses: [],
    step: 1,
    routes: [],
  },
  mutations: {
    SET_STARTING_POINT(state, point) {
      state.startingPoint = point;
    },
    SET_STARTING_POINT_ADDRESS(state, id) {
      state.startingPointAddressId = id;
    },
    SET_NUMBER_VEHICLES(state, nr) {
      state.vehicles = nr;
    },
    ADD_DESTINATION_ADDR(state, addr) {
      state.destinationAddresses.push(addr);
    },
    REMOVE_DESTINATION_ADDR(state, addr) {
      let index = state.destinationAddresses.indexOf(addr);
      if (index > -1) {
        state.destinationAddresses.splice(index, 1);
      }
    },
    SET_STEP(state, step) {
      state.step = step;
    },
    SET_ROUTES(state, routes) {
      state.routes = routes;
    },
  },
  actions: {
    setStartingPoint({ commit }, point) {
      commit("SET_STARTING_POINT", point);
    },
    setStartingPointAddress({ commit }, point) {
      commit("SET_STARTING_POINT_ADDRESS", point);
    },
    setNumberVehicles({ commit }, nr) {
      commit("SET_NUMBER_VEHICLES", nr);
    },
    addDestinationAddr({ commit }, addr) {
      commit("ADD_DESTINATION_ADDR", addr);
    },
    removeAddr({ commit }, addr) {
      commit("REMOVE_DESTINATION_ADDR", addr);
    },
    setStep({ commit }, step) {
      commit("SET_STEP", step);
    },
    route({ commit, state }) {
      let startPoint = state.startingPointAddressId;
      let vehicles = state.vehicles;
      let destinationAddresses = state.destinationAddresses;
      console.log(startPoint, vehicles);

      var urlString = "";
      for (var i = 0; i < destinationAddresses.length; i++) {
        urlString += `,place_id:${destinationAddresses[i].placeId}`;
      }

      var url = `https://fhdjznuffh.execute-api.us-east-1.amazonaws.com/test/transactions?total_vehicles=${vehicles}&place_ids=place_id:${startPoint}${urlString},place_id:${startPoint}`;
      //var url = `https://fhdjznuffh.execute-api.us-east-1.amazonaws.com/test/transactions?total_vehicles=2&place_ids=place_id:ChIJCbLcqytcK4gRtdaYwCVedJw,place_id:ChIJuT-FQh33K4gRrzYJDrpOogo,place_id:ChIJ65IYSOg0K4gRsVr5ggDoGfg,place_id:ChIJr__Ff0Q0K4gRMVaABWFbpQk,place_id:ChIJyf3Vi0nL1IkRXukSZKhSmQY`;

      axios
        .get(url)
        .then((response) => {
          commit("SET_ROUTES", response.data);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    destinationAddresses: (state) => {
      return state.destinationAddresses;
    },
    step: (state) => {
      return state.step;
    },
    startingPoint: (state) => {
      return state.startingPoint;
    },
    routes: (state) => {
      return state.routes;
    },
  },
  modules: {},
});
