<!-- @format -->

<template>
  <v-container fluid>
    <template v-if="step == 1">
      <v-row justify="center" align="center" style="margin-bottom: 30px;">
        <v-col cols="12">
          <v-row justify="center" align="center">
            <div class="display-3">
              Enter the starting address
            </div>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center" align="center">
            <gmap-autocomplete
              class="introInput"
              ref="input"
              :value="description"
              @place_changed="setPlace"
              :options="{
                strictBounds: true,
              }"
              style="border-style: solid; width: 50%"
            >
            </gmap-autocomplete>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center" align="center">
            <GmapMap
              :center="{ lat: lat, lng: lng }"
              :zoom="7"
              map-type-id="terrain"
              style="width: 500px; height: 300px;margin-top: 20px"
            >
            </GmapMap>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center" align="center">
            <div class="font-weight-bold">
              <template v-if="address.length > 0">
                Starting at the address
                {{ address }}
              </template>
            </div>
          </v-row>
        </v-col>
        <v-col cols="12">
          <template v-if="!buttonDisabled">
            <v-row justify="center" align="center">
              <div class="font-weight-bold">
                <v-btn @click="nextStep">Next step</v-btn>
              </div>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-row justify="center" align="center" style="margin-bottom: 20px">
          <div class="numberCircle">1</div>
          <div class="display-1">
            Starting at the address
            {{ address }}
          </div>
        </v-row>
      </v-col>
    </template>
  </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  computed: {
    google: gmapApi,
    step() {
      return this.$store.getters["step"];
    },
    startingPoint() {
      return this.$store.getters["startingPoint"];
    },
    buttonDisabled() {
      if (this.startingPoint.length == 0) return true;
      return false;
    },
  },
  data: () => ({
    plusIcon: "fas fa-plus",
    minusIcon: "fas fa-minus",
    description: "",
    vehicles: 0,
    startingAddress: "",
    startingAddressPlaceId: "",
    lat: 0,
    lng: 0,
    address: "",
    foo: 1,
    exampleRules: [
      (v) => v > 4 || "Min number is 1",
      (v) => v < 1 || "Max number is 4",
    ],
  }),
  methods: {
    nextStep() {
      this.$store.dispatch("setStep", 2);
    },
    search() {
      if (this.serviceName.length > 0) {
        this.$router.push(`/search-by-name/${this.serviceName}`);
      } else {
        this.$router.push(
          `/search-by-zipcode/${localStorage.getItem("zipCode").trim()}`
        );
      }
    },
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      if (!place) return;

      this.latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      this.$store.dispatch("setStartingPoint", place.formatted_address);
      this.$store.dispatch("setStartingPointAddress", place.place_id);
      this.address = place.formatted_address;
      this.lat = lat;
      this.lng = lng;

      var geocoder = new this.google.maps.Geocoder();
      var latlng = new this.google.maps.LatLng(lat, lng);
      geocoder.geocode({ latLng: latlng }, function(results, status) {
        if (status == this.google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            console.log("ok");
          } else {
            alert("No results found");
          }
        } else {
          alert("Geocoder failed due to: " + status);
        }
      });
    },
    placeChanged() {
      console.log("change");
    },
  },
};
</script>

<style>
.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 1px;

  background: #fff;
  border: 2px solid black;
  color: black;
  text-align: center;
  margin-right: 10px;

  font: 32px Arial, sans-serif;
}
</style>
