<!-- @format -->

<template>
  <v-row justify="center" align="center" style="margin-bottom: 30px;">
    <template v-if="step == 2">
      <v-col cols="12">
        <v-row
          justify="center"
          align="center"
          style="width: 50%;margin: 0 auto"
        >
          <div class="display-2">
            How many vehicles are you dispatching?
          </div>
        </v-row>
        <v-row justify="center" align="center" style="margin-top: 20px;">
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="vehicles"
              type="number"
              label="Number"
              :rules="numberRules"
            ></v-text-field>
          </v-form>
        </v-row>
        <v-row justify="center" align="center" style="margin-top: 20px;">
          <v-btn @click="setNrVehicles" :disabled="!valid">SET</v-btn>
        </v-row>
        <v-row justify="center" align="center" style="margin-top: 20px;">
          <v-col cols="6">
            <v-row justify="end" align="center">
              <div class="font-weight-bold">
                <v-btn @click="previousStep">Previous step</v-btn>
              </div>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row justify="start" align="center">
              <div class="font-weight-bold">
                <v-btn @click="nextStep" :disabled="!canGoToNextStep"
                  >Next step</v-btn
                >
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <div class="numberCircle">2</div>
          <div class="display-1">
            Dispatching
            {{ vehicles }} vehicles
          </div>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    vehicles: 1,
    valid: false,
    numberRules: [
      (vehicles) => vehicles < 5 || "Number must be between 1-4",
      (vehicles) => vehicles > 0 || "Number must be between 1-4",
    ],
    canGoToNextStep: false,
  }),
  computed: {
    buttonDisabled() {
      if (this.vehicles < 1 && this.vehicles > 4) return true;
      return false;
    },
    step() {
      return this.$store.getters["step"];
    },
  },
  methods: {
    setNrVehicles() {
      this.$store.dispatch("setNumberVehicles", this.vehicles);
      this.canGoToNextStep = true;
    },
    nextStep() {
      this.$store.dispatch("setStep", 3);
    },
    previousStep() {
      this.$store.dispatch("setStep", 1);
    },
  },
};
</script>

<style>
.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 1px;

  background: #fff;
  border: 2px solid black;
  color: black;
  text-align: center;
  margin-right: 10px;

  font: 32px Arial, sans-serif;
}
</style>
