<!-- @format -->

<template>
  <v-row justify="center" align="center">
    <template v-if="step == 3">
      <v-col cols="12">
        <v-row justify="center" align="center" style="margin-bottom: 20px">
          <div class="numberCircle">3</div>
          <div class="display-3">
            Enter the destination addresses
          </div>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <gmap-autocomplete
            class="introInput"
            ref="input"
            :value="description"
            @place_changed="setPlace"
            :options="{
              strictBounds: true,
            }"
            style="border-style: solid; width: 50%"
          >
          </gmap-autocomplete>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="center" align="center" style="margin-bottom: 30px;">
          <GmapMap
            :center="{ lat: lat, lng: lng }"
            :zoom="7"
            map-type-id="terrain"
            style="width: 500px; height: 300px;margin-top: 20px"
          >
          </GmapMap>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <div class="font-weight-bold">
            # Address
          </div>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, index) in destinationAddr">
          <v-row
            justify="center"
            align="center"
            style="margin-top: 20px"
            :key="item.placeId"
          >
            <div>
              {{ index + 1 }} {{ item.description
              }}<v-btn icon @click="removeDest(item)"
                ><v-icon>fas fa-times</v-icon></v-btn
              >
            </div>
          </v-row>
        </template>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <div class="numberCircle">3</div>
          <div class="display-1">
            {{ destinationAddr.count }} destination addresses
          </div>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  computed: {
    google: gmapApi,
    destinationAddr() {
      return this.$store.getters["destinationAddresses"];
    },
    step() {
      return this.$store.getters["step"];
    },
  },
  data: () => ({
    description: "",
    lat: 0,
    lng: 0,
    address: "",
    placeId: "",
  }),
  methods: {
    search() {
      if (this.serviceName.length > 0) {
        this.$router.push(`/search-by-name/${this.serviceName}`);
      } else {
        this.$router.push(
          `/search-by-zipcode/${localStorage.getItem("zipCode").trim()}`
        );
      }
    },
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      if (!place) return;

      this.latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      this.address = place.formatted_address;
      this.placeId = place.place_id;
      var destAddr = {};
      destAddr.description = this.address;
      destAddr.placeId = this.placeId;
      this.$store.dispatch("addDestinationAddr", destAddr);
      this.lat = lat;
      this.lng = lng;

      var geocoder = new this.google.maps.Geocoder();
      var latlng = new this.google.maps.LatLng(lat, lng);
      geocoder.geocode({ latLng: latlng }, function(results, status) {
        if (status == this.google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            console.log("ok");
          } else {
            alert("No results found");
          }
        } else {
          alert("Geocoder failed due to: " + status);
        }
      });
    },
    placeChanged() {
      console.log("change");
    },
    removeDest(dest) {
      this.$store.dispatch("removeAddr", dest);
    },
  },
};
</script>

<style></style>
