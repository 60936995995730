<!-- @format -->

<template>
  <v-container fluid
    ><v-row justify="center" align="center" class="my-12">
      <div class="display-1">Starting at {{ startingPoint }}</div></v-row
    >
    <v-row justify="center" align="center">
      <v-btn @click="goToHome()">Done</v-btn>
    </v-row>

    <template v-for="(v, index) in vehicles">
      <v-row
        justify="center"
        align="center"
        :key="index"
        class="display-1 my-12"
      >
        Vehicle {{ v.vehicle_id + 1 }} is
        {{ (v.route_distance / 1000).toFixed(1) }}km <!--and will take
        {{ minutes }} before stops -->
      </v-row>
    </template>

    <v-row justify="center" align="center">
      <v-col cols="6">
        <template v-for="v in vehicles">
          <div :key="v.vehicle_id">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-row justify="center" align="center">
                  <div class="display-2 my-2">
                    Vehicle {{ v.vehicle_id + 1 }}
                  </div>
                </v-row>
              </v-col>
              <template v-for="(p, index) in v.place_ids">
                <v-col cols="12" :key="p">
                  <v-row justify="center" align="center" class="my-2">
                    {{ index + 1 }} {{ p }}
                  </v-row>
                </v-col>
              </template>
            </v-row>

            <v-row justify="center" align="center"
              ><div class="font-weight-bold my-12">
                This trip is {{ (v.route_distance / 1000).toFixed(1) }}km <!--and
                will take x mins before stops-->
              </div></v-row
            >
          </div>
        </template>
      </v-col>
      <!--<v-col cols="6">
        <template v-for="v in vehicles">
          <GmapMap
            :center="{ lat: 41.85, lng: -87.65 }"
            :zoom="7"
            map-type-id="terrain"
            style="width: 600px; height: 400px;margin-top: 20px"
            :key="v.vehicle_id"
          >
            <DirectionsRenderer
              travelMode="DRIVING"
              :origin="origin"
              :destination="destionation"
              :waypoints="wayopoints"
            />
          </GmapMap>
        </template>
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script>
//import DirectionsRenderer from "@/components/DirectionsRenderer";

export default {
  components: {
    //DirectionsRenderer,
  },
  data: () => ({
    start: "Denver, Colorado, Statele Unite ale Americii",
    end: "3328 Bayview Rd, Oakville, ON L6L 5L8, Canada",
    waypoints: ["1328 Bayview Rd, Oakville, ON L6L 5L8, Canada"],
  }),
  methods: {
    goToHome() {
      this.$store.dispatch("setStep", 1);
      this.$router.push("/");
    },
  },
  computed: {
    startingPoint() {
      return this.$store.getters["startingPoint"];
    },
    finalPoint() {
      return this.$store.getters["destinationAddresses"];
    },
    vehicles() {
      return this.$store.getters["routes"];
    },
    minutes() {
      return "21";
    },
    origin() {
      if (!this.startingPoint) return null;
      console.log(this.startingPoint);
      return { query: this.startingPoint };
    },
    destionation() {
      if (!this.finalPoint[0].description) return null;
      console.log(this.finalPoint[0].description);
      return { query: this.finalPoint[0].description };
    },
    wayopoints() {
      if (!this.waypoints) return null;
      return this.waypoints;
    },
  },
};
</script>

<style></style>
