<!-- @format -->

<template>
  <v-container fluid style="margin-top: 30px;">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <StartingAddress></StartingAddress>
        <Vehicles />
        <DestinationAddress />
        <v-col cols="12">
          <v-row justify="center" align="center" style="margin-top: 20px">
            <v-btn @click="route">Route (GO!)</v-btn>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import StartingAddress from "@/components/StartingAddress";
import Vehicles from "@/components/Vehicles";
import DestinationAddress from "@/components/DestinationAddress";

export default {
  components: {
    StartingAddress,
    Vehicles,
    DestinationAddress,
  },
  computed: {
    google: gmapApi,
  },
  data: () => ({}),
  methods: {
    async route() {
      await this.$store.dispatch("route");
      this.$router.push("/route");
    },
  },
};
</script>
